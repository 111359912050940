<template>
  <a-layout>
    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item>
        <!-- {{ siderData.title }}@click.stop="toggleSider" -->
        {{ $route.name }}
      </a-breadcrumb-item>
    </a-breadcrumb>

    <a-layout-content class="content">
      <router-view v-slot="{ Component }">
        <transition name="slide-left" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </a-layout-content>

   
    <a-layout-footer class="footer">&copy;2021 Springup Inc.</a-layout-footer>
  </a-layout>
</template>

<script>
import { onMounted, onUnmounted, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";

export default {
  components: {},
  setup() {
    const router = useRouter();
    const state = reactive({
      data: [],
      
      
      siderData: {
        title: router.currentRoute.value.name,
        list: [],
      },
      siderStatus: false,
      isLoading: true,
    });
    const handleTableChange = (pagination, filters, sorter) => {
      console.log(pagination);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    };
    const fetch = (params = {}) => {
      console.log("params:", params);
      this.loading = true;
      reqwest({
        url: "https://randomuser.me/api",
        method: "get",
        data: {
          results: 10,
          ...params,
        },
        type: "json",
      }).then((data) => {
        const pagination = { ...this.pagination };
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = 200;
        this.loading = false;
        this.data = data.results;
        this.pagination = pagination;
      });
    };
    // 打开/关闭侧边栏
    const toggleSider = () => {
      state.siderStatus = !state.siderStatus;
    };
    const handleClick = () => {
      if (state.siderStatus === true) {
        state.siderStatus = false;
      }
    };
    // 搜索
    const onSearch = (val) => {
      console.log(val);
    };
    onMounted(() => {
      document.addEventListener("click", handleClick);
      setTimeout(() => {
        state.isLoading = false;
      }, 1000);
    });
    onUnmounted(() => {
      document.removeEventListener("click", handleClick);
    });
    return {
      ...toRefs(state),
      handleTableChange,
      toggleSider,
      onSearch,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/style/mixins";

.content {
  background-color: $color-white;
  padding: 15px;
  overflow: auto;
}
.footer {
  text-align: center;
}
.table {
  margin-top: 15px;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(30px);
  opacity: 0;
}
</style>
